<template>
  <div class="zt-page-content">
    <div class="page-header">服务介绍</div>
    <div class="zt-block tab">
      <el-form ref="search" :inline="true" :label-width="'120px'">
        <el-row class="search">
          <el-form-item label="标题：" size="mini">
            <el-input
              v-model="search.journalismTitle"
              clearable
              size="mini"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="类型：" size="mini">
            <el-select
              v-model="search.noticeTypeCode"
              clearable
              size="mini"
              placeholder="请选择"
              ><el-option
                v-for="(item, index) in searchSelect"
                :key="index"
                :label="item.noticeTypeName"
                :value="item.noticeTypeCode"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="发布日期：" size="mini">
            <el-date-picker
              size="small"
              v-model="releaseTime"
              type="daterange"
              range-separator="~"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="dataSearch"
            ></el-date-picker>
          </el-form-item>
        </el-row>
        <el-row type="flex" justify="end">
          <el-form-item label size="mini">
            <el-button
              type="primary"
              size="mini"
              @click="onSearch"
              :loading="loading"
              icon="el-icon-search"
              >查询</el-button
            >
            <el-button size="mini" @click="onReset" icon="el-icon-refresh-left"
              >重置</el-button
            >
          </el-form-item>
        </el-row>
      </el-form>
    </div>
    <div style="margin: 20px">
      <el-button
        size="mini"
        type="primary"
        icon="el-icon-plus"
        @click="addTable"
        >新增</el-button
      >
      <el-button
        size="mini"
        type="warning"
        icon="el-icon-edit"
        :disabled="selectList.length == 0"
        @click="editTable"
        >修改</el-button
      >
      <el-button
        size="mini"
        type="danger"
        icon="el-icon-delete"
        :disabled="selectList.length == 0"
        @click="deleteTable"
        >删除</el-button
      >
    </div>
    <div class="zt-block" style="height: calc(100% - 175px)">
      <div class="list" style="height: calc(100% - 60px); overflow-y: auto">
        <el-table
          v-loading="loading"
          :data="list"
          style="width: 100%"
          @select-all="onSelectRowAll"
          @select="onSelectRow"
          :empty-text="'无数据'"
        >
          <el-table-column
            align="center"
            width="60"
            type="selection"
          ></el-table-column>
          <el-table-column
            prop="noticeTitle"
            label="标题"
            width="150"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.noticeTitle }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="noticeTypeName"
            label="类型"
            width="150"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.noticeTypeName }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="noticePicture"
            label="图片"
            width="150"
            align="center"
          >
            <template slot-scope="scope">
              <img
                v-if="scope.row.noticePicture"
                :src="scope.row.noticePicture"
                style="width: 100px; height: 50px"
              />
              <div v-else>暂无图片</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="noticeDesc"
            label="内容"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.noticeDesc }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="releaser"
            label="发布者"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.releaser }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            width="220"
            align="center"
            label="发布时间"
            :formatter="dateTime"
          >
          </el-table-column>
          <el-table-column
            prop="meter_number"
            label="操作"
            width="120"
            align="center"
          >
            <template slot-scope="scope">
              <el-button type="text" @click="detail(scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="padding: 12px">
        <el-pagination
          background
          @current-change="onPageChange"
          @size-change="onPageSizeChange"
          :current-page="search.pageIndex"
          :page-size="search.pageSize"
          layout="prev, pager, next"
          :total="search.total"
        ></el-pagination>
      </div>
      <el-dialog
        class="form"
        v-loading="visibleLoading"
        :fullscreen="false"
        :title="form.formTitle"
        :custom-class="'form-dialog'"
        width="1200px"
        :center="true"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :modal-append-to-body="true"
        :visible.sync="form.formShow"
        @close="formClose"
      >
        <el-form
          ref="form"
          :model="form.baseOut"
          :inline="true"
          label-width="180px"
          :rules="addFormRules"
        >
          <el-col :span="24">
            <el-form-item label="标题：" prop="noticeTitle" size="mini">
              <el-input
                :disabled="disabled"
                v-model="form.baseOut.noticeTitle"
                clearable
                size="mini"
                placeholder="请输入"
                :maxlength="50"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="10">
            <el-form-item label="类型：" prop="noticeTypeCode" size="mini">
              <el-select
                :disabled="disabled"
                v-model="form.baseOut.noticeTypeCode"
                clearable
                size="mini"
                placeholder="请输入"
                :maxlength="50"
              >
                <el-option
                  v-for="(item, index) in select"
                  :key="index"
                  :label="item.noticeTypeName"
                  :value="item.noticeTypeCode"
                ></el-option
              ></el-select> </el-form-item
          ></el-col>
          <el-col :span="24">
            <el-form-item label="图片：" size="mini">
              <el-upload
                :class="src != undefined ? 'left' : ''"
                :disabled="disabled"
                :action="BASE_URL + '/api/TencentOssApi/ImgImports'"
                :on-preview="handlePictureCardPreview"
                :before-upload="beforeAvatarUpload"
                :on-success="handleAvatarSuccess"
                :show-file-list="false"
              >
                <img
                  v-if="src != undefined"
                  style="width: 60%; height: 60%"
                  :src="src"
                />
                <i
                  v-else
                  class="el-upload el-upload--picture-card el-icon-plus"
                ></i>
              </el-upload> </el-form-item
          ></el-col>
          <el-col :span="24"
            ><el-form-item label="发布者：" prop="releaser" size="mini">
              <el-input
                :disabled="disabled"
                v-model="form.baseOut.releaser"
                clearable
                size="mini"
                placeholder="请输入"
                :maxlength="50"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="24"
            ><el-form-item label="内容：" prop="noticeDesc" size="mini">
              <el-input
                type="textarea"
                :disabled="disabled"
                v-model="form.baseOut.noticeDesc"
                clearable
                :maxlength="200"
              ></el-input> </el-form-item
          ></el-col>
        </el-form>
        <div slot="footer" style="margin-top: 50px">
          <el-button size="mini" @click="form.formShow = false">取消</el-button>
          <el-button v-if="!disabled" size="mini" type="primary" @click="onSave"
            >立即发布</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { WLBASE_URL } from "@/config";
export default {
  components: {},
  filters: {
    filter(val, isDeletedList) {
      return isDeletedList.filter((item) => val == item.value)[0].label;
    },
  },
  data() {
    return {
      disabled: false,
      BASE_URL: WLBASE_URL,
      isDeletedList: [
        { label: "未审核", value: 0 },
        { label: "已审核", value: 1 },
      ],
      // 日期选择
      releaseTime: "",
      src: undefined,
      loading: false,
      selectList: [],
      searchSelect: [{ noticeTypeCode: "", noticeTypeName: "全部" }],
      search: {
        noticeTitle: "",
        noticeTypeCode: "",
        releaseTimeStart: "",
        releaseTimeEnd: "",
        orgId: undefined,
        tenantId: 0,
        pageIndex: 1,
        pageSize: 10,
        total: 0, //总数
      },
      visibleLoading: false,
      form: {
        formTitle: "",
        formShow: false,
        baseOut: {
          id: "",
          orgId: "",
          tenantId: 0,
          noticeTypeCode: "",
          noticeTypeName: "",
          noticeTitle: "",
          noticePicture: "", //图片路径
          noticeDesc: "",
          releaser: "", //发布者
        },
      },
      select: [],
      // 表单校验
      addFormRules: {
        noticeTitle: [
          { required: true, message: "请输入标题", trigger: "blur" },
        ],
        noticeTypeName: [
          { required: true, message: "请选择类型", trigger: "blur" },
        ],
        releaser: [
          { required: true, message: "请输入发布者", trigger: "blur" },
        ],
        noticeDesc: [
          { required: true, message: "请输入内容", trigger: "blur" },
        ],
      },
      total: 0,
      list: [],
    };
  },
  async created() {
    this.getList();
    this.getType();
  },
  methods: {
    getType() {
      let url = WLBASE_URL + "/api/home/PagingNoticetypeDal";
      this.axios({
        url,
        method: "POST",
        data: { pageIndex: 1, pageSize: 10 },
      }).then((res) => {
        if (res.data.success) {
          this.select = res.data.content.datas.map((item) => {
            let obj = {};
            obj.noticeTypeCode = item.noticeTypeCode;
            obj.noticeTypeName = item.noticeTypeName;
            return obj;
          });
          this.searchSelect = this.searchSelect.concat(this.select);
        } else {
          this.$message.error("获取类型失败");
        }
      });
    },
    async handleAvatarSuccess(res, file) {
      // this.src = URL.createObjectURL(file.raw);
      if (res.success) {
        let data = {
          id: res.content,
        };
        let url = WLBASE_URL + "/api/TencentOssApi/DocumentPreview";
        this.axios({
          url,
          method: "POST",
          data,
        }).then((res) => {
          if (res.data.success) {
            this.src = res.data.content;
          } else {
            this.$message.error(res.data.msg);
          }
        });
        // documentPreview(data).then((res) => {
        //   this.src = res.content;
        // });
        // console.log(this.addNoticePic);
      }
    },
    //校验图片
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 5MB!");
      }
      return isJPG && isLt2M;
    },
    // 图片放大
    handlePictureCardPreview(file) {
      // console.log("放大");
      this.src = file.url;
    },
    // 时间转换2--表格插槽
    dateTime(rowData) {
      // console.log(rowData);
      var d = new Date(rowData.modifiedOn);
      var a =
        d.getFullYear() +
        "-" +
        (d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1) +
        "-" +
        (d.getDate() < 10 ? "0" + d.getDate() : d.getDate()) +
        " " +
        (d.getHours() < 10 ? "0" + d.getHours() : d.getHours()) +
        ":" +
        (d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes()) +
        ":" +
        (d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds());
      // console.log(a);
      return a;
    },
    addTable() {
      this.form.formShow = true;
      this.form.formTitle = "新增";
      this.src = undefined;
      this.disabled = false;
      this.form.baseOut = {
        id: "",
        orgId: this.search.orgId,
        tenantId: 0,
        noticeTypeCode: "",
        noticeTypeName: "",
        noticeTitle: "",
        noticePicture: "", //图片路径
        noticeDesc: "",
        releaser: "", //发布者
      };
    },
    formClose() {
      this.$refs.form.resetFields();
    },
    deleteTable() {
      let url = WLBASE_URL + "/api/PassTokenWaybill/DeleteNoticeDal";
      let data = {
        idList: [],
      };
      if (this.selectList.length > 0) {
        data.idList = this.selectList.map((item) => item.id);
        this.$confirm("确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.axios({
            url,
            method: "POST",
            data,
          }).then((res) => {
            if (res.data.success) {
              this.$message.success(res.data.content);
              this.getList();
            } else {
              this.$message.error(res.data.msg);
            }
          });
        });
      } else {
        this.$message.error("请先勾选数据");
      }
    },
    detail(data) {
      this.form.formTitle = "详情";
      this.form.formShow = true;
      this.disabled = true;
      this.form.baseOut = {
        noticeTypeCode: data.noticeTypeCode,
        // noticeTypeName: data.noticeTypeName,
        noticeTitle: data.noticeTitle,
        noticePicture: data.noticePicture, //图片路径
        noticeDesc: data.noticeDesc,
        releaser: data.releaser, //发布者
      };
      this.src = data.noticePicture;
    },
    editTable() {
      if (this.selectList.length == 1) {
        this.disabled = false;
        this.form.baseOut.id = this.selectList[0].id;
        this.form.baseOut = {
          id: this.selectList[0].id,
          orgId: this.search.orgId,
          tenantId: 0,
          noticeTypeCode: this.selectList[0].noticeTypeCode,
          noticeTypeName: this.selectList[0].noticeTypeName,
          noticeTitle: this.selectList[0].noticeTitle,
          noticePicture: this.selectList[0].noticePicture, //图片路径
          noticeDesc: this.selectList[0].noticeDesc,
          releaser: this.selectList[0].releaser, //发布者
        };
        this.src = this.selectList[0].noticePicture;
        this.form.formTitle = "修改新闻";
        this.form.formShow = true;
      } else if (this.selectList.length > 1) {
        this.$message.error("只能勾选一条数据");
      } else {
        this.$message.error("请勾选一条数据");
      }
    },
    onSave() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          if (this.src == undefined) {
            return this.$message.error("请上传图片");
          }
          this.visibleLoading = true;
          this.form.baseOut.noticeTypeName = this.select.find(
            (item) => item.noticeTypeCode == this.form.baseOut.noticeTypeCode
          ).noticeTypeName;
          let url = WLBASE_URL + "/api/PassTokenWaybill/SaveNoticeDal";
          this.form.baseOut.orgId = this.search.orgId;
          this.form.baseOut.noticePicture = this.src;
          this.axios({
            url,
            method: "POST",
            data: this.form.baseOut,
          }).then((res) => {
            if (res.data.success) {
              this.$message.success(res.data.content);
              this.visibleLoading = false;

              this.getList();
              this.selectList = [];
              this.form.formShow = false;
            } else {
              this.$message.error(res.data.msg);
            }
          });
        }
      });
    },
    onSelectRow(e) {
      this.selectList = e;
    },
    onSelectRowAll(e) {
      this.selectList = e;
    },
    onReset() {
      this.search = {
        noticeTitle: "",
        noticeTypeCode: "",
        releaseTimeStart: "",
        releaseTimeEnd: "",
        orgId: this.search.orgId,
        tenantId: 0,
        pageIndex: 1,
        pageSize: 10,
        total: 0, //总数
      };
      this.dataSearch("reset");
      this.onSearch();
    },
    onSearch() {
      this.getList(1);
    },
    onPageChange: function (pageIndex) {
      this.search.pageIndex = pageIndex;
      this.getList(pageIndex);
    },
    onPageSizeChange: function (pageSize) {
      this.search.pageIndex = 1;
      this.search.pageSize = pageSize;
      this.getList();
    },
    // 日期选择器
    dataSearch(val) {
      if (val == "reset" || val == null) {
        this.releaseTime = "";

        this.search.releaseTimeStart = "";
        this.search.releaseTimeEnd = "";
      } else {
        // console.log(val);
        // console.log(val[0]);
        // console.log(this.dateFormat(val[0]));
        this.search.releaseTimeStart = this.dateFormat(val[0]);

        // console.log(val[1]);
        // console.log(this.dateFormat(val[1]));
        this.search.releaseTimeEnd = this.dateFormat(val[1]);
      }
    },
    // 时间转换
    dateFormat(dateData) {
      var date = new Date(dateData);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      const time = y + "-" + m + "-" + d;
      return time;
    },
    getList() {
      this.loading = true;
      if (WLBASE_URL == "https://consul.mes.emergen.cn:6012") {
        this.search.orgId = 2754;
      } else if (WLBASE_URL == "https://consul.mes.emergen.cn:5004") {
        this.search.orgId = 2959;
      } else if (WLBASE_URL == "https://blcapi.nk001.com") {
        this.search.orgId = 2139;
      }
      let url = WLBASE_URL + "/api/PassTokenWaybill/PagingNoticeDal";
      this.axios({
        url,
        method: "POST",
        data: this.search,
      }).then((res) => {
        if (res.data.success) {
          this.loading = false;
          this.search.total = res.data.content.recordCount;
          this.list = res.data.content.datas;
        } else {
          this.$message.error("请求数据失败");
        }
      });
    },
  },
};
</script>
<style scoped src="../style.css"></style>
<style scoped>
.tab {
  padding: 0 20px;
}
.tab_menu .menu_item {
  display: inline-block;
  vertical-align: top;
  width: 80px;
  height: 55px;
  text-align: center;
  line-height: 55px;
  margin-right: 50px;
  cursor: pointer;
  font-size: 14px;
  color: #808080;
}
.tab_menu .menu_item.cur {
  color: #4d4d4d;
  border-bottom: 2px solid #4d4d4d;
}

div >>> .el-table .cell {
  text-align: center;
}
div >>> .el-dialog {
  min-width: 550px;
}
div >>> .el-dialog__body {
  /* background: #f5f5f5; */
  background: #fff;
}
.xqtitle {
  font-size: 16px;
  font-weight: 600;
  position: relative;
  line-height: 24px;
  padding: 0 2px;
  display: inline-block;
  margin-bottom: 20px;
}
.xqtitle::after {
  position: absolute;
  bottom: -4px;
  height: 4px;
  width: 100%;
  background: #5074ee;
  content: "";
  left: 0;
}
#dialog >>> .el-input__inner {
  width: 260px;
  height: 40px;
}
/* .el-col-24 >>> .el-form-item__content > input {
  width: 950px;
  line-height: 40px;
} */
.el-col-24 >>> textarea {
  width: 950px;
  height: 100px;
}
textarea #dialog >>> .el-textarea__inner {
  width: 950px;
  height: 100px;
}
.el-col-24 >>> .el-input__inner {
  width: 950px;
  height: 40px;
}
#dialog >>> .el-table {
  background: #f5f7fa;
}
.search {
  padding-top: 15px;
}
.search >>> .el-input--mini .el-input__inner {
  height: 31.99px;
  line-height: 31.99px;
}
.left {
  display: inline-block;
}
</style>
